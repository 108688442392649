import React from "react"
import '../styles/white-section.scss'
import './CartItem.scss'
import { FormattedMessage } from "gatsby-plugin-intl"

const Cart = () => {
  let cartItems = 0; //JSON.parse(localStorage.getItem('cartItems'));
  let cartLength = 0;
  let cartSubTotal = 0;
  let cartVat = 0;
  let cartTotal = 0;
  if (cartItems && cartItems.length > 0) {
    cartItems.map(cartItem => {
      cartSubTotal = cartSubTotal + (cartItem.price * cartItem.quantity);
      cartLength = cartLength + parseInt(cartItem.quantity, 10);
      return cartItem;
    });
    cartVat = cartSubTotal * 0.23;
    cartTotal = cartSubTotal + cartVat;
  }

  return (
    <section className="white-section">
      <div className="container page-content">
        <div className="row">
          <div className="col-md-7">
            <h2>
              <FormattedMessage id="cart.title" />
            </h2>
            <div className="cart-items">
            </div>
            <div className="cart-total">
              <p>
                <span className="label"><FormattedMessage id="cart.sub_total" />:</span>&nbsp;
                <span className="value cart-sub-total">€{cartSubTotal.toFixed(2)}</span>
              </p>
              <p>
                <span className="label"><FormattedMessage id="cart.vat" />:</span>&nbsp;
                <span className="value cart-vat">€{cartVat.toFixed(2)}</span> </p>
              <p>
                <span className="label"><FormattedMessage id="cart.total" />:</span>&nbsp;
                <span className="value cart-total-value">€{cartTotal.toFixed(2)}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default Cart
