import React from "react"
import '../styles/blue-section.scss'
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

const Checkout = () => {
  const intl = useIntl()
  return (
    <section className="blue-section">
      <h2>
        <FormattedMessage id="checkout.title" />
      </h2>
      <form className="blue-form">
        <h3><FormattedMessage id="checkout.billing_address" /></h3>
        <input type="text" className="inverse" placeholder={intl.formatMessage({ id: "forms.name" })} />
        <input type="text" className="inverse" placeholder={intl.formatMessage({ id: "forms.address1" })} />
        <input type="text" className="inverse" placeholder={intl.formatMessage({ id: "forms.address2" })} />
        <input type="text" className="inverse" placeholder={intl.formatMessage({ id: "forms.zip" })} style={{width: 100}} /> &nbsp;-&nbsp;
        <input type="text" className="inverse" placeholder={intl.formatMessage({ id: "forms.zip" })} style={{width: 40}} />
        <input type="text" className="inverse" placeholder={intl.formatMessage({ id: "forms.city" })} />
        <select>
          <option>{intl.formatMessage({ id: "forms.state" })}</option>
        </select>
        <h3><FormattedMessage id="checkout.payment_method" /></h3>
        <p>
          <span className="radio-check-option inverse">
            <label htmlFor="option-paypal">Paypal</label>
            <input type="radio" id="option-paypal" value="paypal" name="payment"/>
          </span>
        </p>
        <input type="text" className="inverse" placeholder={intl.formatMessage({ id: "forms.paypal_id" })} />
        <button className="vbtn vbtn--gradient" style={{margin: '35px 0 0 0'}}><FormattedMessage id="buttons.checkout" /></button>
      </form>
    </section>
  )
};

export default Checkout