import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cart from "../components/Cart"
import Checkout from "../components/Checkout"
import { useIntl } from "gatsby-plugin-intl"

const CartPage = () => {
  const intl = useIntl()
  return (
    <Layout contentType="internal">
      <SEO title={intl.formatMessage({ id: "cart.title" })} />
      <Cart />
      <Checkout />
    </Layout>
  )
};

export default CartPage